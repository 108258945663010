.carouselBanner {
    padding: 1rem 0;
}

.carouselWrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.carouselWrapper::before, .carouselWrapper::after {
    background: linear-gradient(to right, #FFF 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 60px;
    position: absolute;
    width: 100px;
    z-index: 2;
}

.carouselWrapper::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}
.carouselWrapper::before {
    left: 0;
    top: 0;
}

.carouselRow {
    --length: attr(key);
    -webkit-animation: scroll 90s linear infinite;
    animation: scroll 90s linear infinite;
    display: flex;
    /* TODO: seems like this property is bugged - slight delay noticed when animation reaches its end */
    /* check by changing the animation property to 10s instead of 90*/
    width: calc(200px * var(--length))!important;
}

.carouselItem {
    user-select: none;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 24px;
    width: auto;
    max-width: 100px!important;
    height: 50px;
    position: relative!important;
}

.carouselItem img {
    height: inherit;
    max-height: 50px;
    width: auto;
    object-fit: contain;
}

@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-200px * 24))}
}

@keyframes loop-da-loop {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}
